.ova-open-time {
	width: 245px;
	p, h2 {
		margin: 0px;
	}
	.content {
		background-color: #ffffff;
		position: relative;
		z-index: 0;
		padding: 0px 10px 24px 24px;
		&:before {
		    content: "";
		    width: 100%;
		    height: 20px;
		    position: absolute;
		    top: -12px;
		    left: 0px;
		    background-color: #ffffff;
		    transform: skew(0,-2deg);
		    z-index: -1;
		}
		h2.title {
			font-family: archivo;
			font-size: 18px;
			line-height: 28px;
			color: #25282e;
			padding: 12px 0 7px 0;
		}
		p.time, p.orther {
			font-size: 15px;
			line-height: 22px;
			color: #777777;
		}
	}
	.text-button {
		a {
			font-family: archivo;
			font-size: 16px;
			line-height: 22px;
			color: #ffffff;
			background-color: #25282e;
			display: block;
			text-align: center;
			padding: 18px 0px 10px 0px;
			position: relative;
			z-index: 0;
			transition: .3s;
			&:before {
			    content: "";
			    width: 50%;
			    height: 20px;
			    background-color: #25282e;
			    position: absolute;
			    bottom: -8px;
			    left: 0;
			    transform: skew(0,8deg);
			    z-index: -1;
			    transition: .3s;
			}
			&:after {
				content: "";
			    width: 50%;
			    height: 20px;
			    background-color: #25282e;
			    position: absolute;
			    bottom: -8px;
			    right: 1px;
			    transform: skew(0,-8deg);
			    z-index: -1;
			    transition: .3s;
			}
			&:hover {
				background-color: #121417;
				&:after, &:before {
					background-color: #121417;
				}
			}
		}
	}
}