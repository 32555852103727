.heading-page{
	background-repeat: no-repeat;
	background-position: center center; 
	background-size: cover;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.3);
	}
	.cta-content{
		position: relative;
		z-index: 2;
		h2{
		    font-size: 60px;
		    color: #fff;
		    padding: 114px 0px 127px 0;
		    margin-top: 0;
		    margin-bottom: 0;
		    line-height: 60px;
		}
	}
}