/********** Fix Group **********/
.header_group {
	.elementor-widget-wrap {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.elementor-element {
			margin: 0px;
			.elementor-text-editor{
				p{
					margin-bottom: 0;
				}
			}
		}
	}
	&.center{
		.elementor-widget-wrap {
			justify-content: center;
		}
	}
	&.dir_left .elementor-widget-wrap {
		justify-content: flex-start;
	}
	&.dir_center .elementor-widget-wrap {
		justify-content: center;
	}
}

.button_gr{
	.elementor-widget-wrap {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		@media(max-width: 767px){
			justify-content: center;
			align-items: baseline;
		}
		.elementor-element {
			margin: 0px;
		}
	}
	&.center{
		.elementor-widget-wrap {
			justify-content: center;
		}
	}
}

.collection_icon{
	.elementor-button-wrapper{
		background: url(../img/arrow-collection.png) no-repeat;
		background-position: 150px center;
	}
}

.ova_button {
	.elementor-button-link {
		background: url(../img/arrow-collection.png) no-repeat;
		background-position: right; 
	}
}
/**** Fix Icon Map ***/

.elementor .elementor-button-wrapper .elementor-button-icon .fa.fa-map-signs:before{
	content: "\f041" !important;
}

/**** Footer ***/

.quick_link{
	&.type1{
		ul{
			padding: 0;
			list-style: none;
			li{
				padding-bottom: 11px;
				&:first-child{
					padding-top: 2px;
				}
				a{
					color: #888888;
					font-size: 16px;
					-webkit-transition: all .3s;
					transition: all .3s;
				}
			}
		}
	}
	&.type2{
		ul{
			padding: 0;
			list-style: none;
			li{
				padding-bottom: 11px;
				&:first-child{
					padding-top: 2px;
				}
				a{
					color: #777777;
					font-size: 16px;
					-webkit-transition: all .3s;
					transition: all .3s;
				}
			}
		}
	}
}

.newsletter{
	padding-top: 5px;
	&.type1{
		.ova-form-mail-chimp{
			.name, .email, .submit{
				-webkit-box-flex: 0;
			    -ms-flex: 0 0 40%;
			    flex: 0 0 100%;
			    padding-right: 0;
			    margin-bottom: 15px;
			    height: 50px;
			    line-height: 50px;
			}
			.name{
				display: none;
			}
			.email{
				input[type=email]{
					border: 0;
					padding-left: 20px;
					padding-right: 20px;
					font-size: 16px;
					color: #999999;
				}
			}
			.submit{
				input[type=submit]{
					background: #e4002b;
					border: 0;
					color: #fff;
					font-size: 16px;
					color: #fff;
					text-transform: uppercase;
					-webkit-transition: all .3s;
					transition: all .3s;
					&:hover{
						background: #c1b696;
					}
				}
			}
		}
	}
	&.type2{
		.ova-form-mail-chimp{
			.name, .email, .submit{
				-webkit-box-flex: 0;
			    -ms-flex: 0 0 40%;
			    flex: 0 0 100%;
			    padding-right: 0;
			    margin-bottom: 15px;
			    height: 50px;
			    line-height: 50px;
			}
			.name{
				display: none;
			}
			.email{
				input[type=email]{
					border: 1px solid #e5e5e5;
					padding-left: 20px;
					padding-right: 20px;
					font-size: 16px;
					color: #999999;
				}
			}
			.submit{
				input[type=submit]{
					background: #25282e;
					border: 0;
					color: #fff;
					font-size: 16px;
					color: #fff;
					text-transform: uppercase;
					-webkit-transition: all .3s;
					transition: all .3s;
					&:hover{
						background: #c1b696;
					}
				}
			}
		}
	}
}

/* Home History */

.contact_header_home_history .elementor-widget-container .elementor-text-editor{
	p{
		line-height: 22px;
	}
}

/* Home Mordem */

.collections_sticky .elementor-column-wrap .elementor-widget-wrap{
	position: -webkit-sticky;
    position: sticky;
    height: 500px;
    top: 256px;
}

/*--- Footer ---*/

.md_footer .elementor-widget-container .elementor-text-editor{
	p{
		line-height: 26px;
		margin-bottom: 15px;
	}
}


/**** Home Minimal (Home-8) ****/

.minimal_footer_add .elementor-widget-container .elementor-text-editor p{
	line-height: 24px;
	margin-bottom: 18px;
}
.minimal_footer_bottom .elementor-widget-container .elementor-text-editor p{
	line-height: 30px;
}